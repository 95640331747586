import { LoginPropsCommon } from "./typings";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { isAndroid, isPWA } from "~/utils/device-check";
import {
  AppleIcon,
  GoogleIcon,
  LinkedinIconColor,
  LogoWithShapes,
} from "../icons";
import { useSearchParams, Link } from "react-router";
import { isFrontend } from "~/hooks/is-frontend";

interface LoginProps extends LoginPropsCommon {
  handleGoogle: () => void;
  handleApple: () => void;
  handleLinkedin?: () => void;
  reset: boolean;
}

export function Login({
  handleGoogle,
  handleApple,
  handleLinkedin,
  reset,
  appRoutes,
  redirectUrl,
  routerUtils,
  enableIntercom,
}: LoginProps) {
  const { useLocation, Link } = routerUtils;
  const [hideAppleSignIn, setHideAppleSignIn] = useState(false);
  const [searchParams] = useSearchParams();
  searchParams.delete("error");

  useEffect(() => {
    if (isPWA() && isAndroid()) {
      setHideAppleSignIn(true);
    }
  }, []);

  return (
    <>
      <div className="flex flex-col justify-center items-center w-full md:w-96">
        <LogoWithShapes />

        <div className="text-center my-15">
          <h2 className="mb-3">Welcome back</h2>
          <div className="text-theme-text-secondary">
            We're excited to see you again.
          </div>
        </div>
        <Link
          className="w-full flex justify-center"
          to={{
            pathname: appRoutes.LoginEmail,
            search: searchParams.toString(),
          }}
        >
          <button
            type="button"
            id="signin-email"
            className="btn-white-md 2xl:btn-white-lg w-full max-w-sm mb-3"
          >
            <EnvelopeIcon />
            <span>Sign in with email</span>
          </button>
        </Link>
        <button
          type="button"
          className="btn-white-md 2xl:btn-white-lg w-full max-w-sm mb-3"
          onClick={handleGoogle}
        >
          <GoogleIcon aria-hidden="true" />
          <span>Sign in with Google</span>
        </button>
        {isFrontend() && !hideAppleSignIn && (
          <>
            <button
              type="button"
              className="btn-white-md 2xl:btn-white-lg w-full max-w-sm mb-3"
              onClick={handleApple}
            >
              <AppleIcon aria-hidden="true" />
              <span>Sign in with Apple</span>
            </button>

            <Link
              className="w-full flex justify-center"
              to={{
                pathname: appRoutes.LoginSAML,
                search: searchParams.toString(),
              }}
            >
              <button
                type="button"
                className="btn-white-md 2xl:btn-white-lg w-full max-w-sm mb-3"
              >
                <span>Sign in with SAML SSO</span>
              </button>
            </Link>
          </>
        )}

        {handleLinkedin && (
          <button
            type="button"
            className="btn-white-md 2xl:btn-white-lg w-full max-w-sm"
            onClick={handleLinkedin}
          >
            <LinkedinIconColor aria-hidden="true" />
            <span>Sign in with LinkedIn</span>
          </button>
        )}
      </div>

      <div className="text-theme-text-secondary mt-6 text-center">
        <p>
          <span className="text-theme-text-primary typography-body-sm-regular">
            Don't have an account?{" "}
          </span>
          <Link
            to={{
              pathname: appRoutes.Signup,
              search: searchParams.toString(),
            }}
            className="link typography-body-sm-regular"
          >
            Sign up
          </Link>
        </p>
      </div>
    </>
  );
}

export default Login;
