import { MetaFunction } from "react-router";
import { Link, useLocation, useSearchParams } from "react-router";
import React from "react";
import { Login } from "~/components/authentication/";
import usePageView from "~/hooks/page-view";
import { AppRoutes } from "~/utils/app-routes";
import { isSafari } from "~/utils/extension-version";
import metrics from "~/utils/metrics";
import { openAuthWindow } from "~/utils/open-auth-window";

export const meta: MetaFunction = () => {
  return [
    {
      title: "Mindstone - Login",
    },
    { name: "description", content: "Login to Mindstone" },
  ];
};

export default function Index() {
  usePageView("Login - Choice");
  // const linkedInFeature = useFeature("linkedin-oauth");

  const [searchParams] = useSearchParams();
  const previousUrl = searchParams.get("redirectUrl") || "/";

  const params = new URLSearchParams(useLocation().search);

  let reset = false;
  if (params.get("reset")) {
    reset = decodeURIComponent(params.get("reset") || "") === "true";
  }

  const handleGoogle = () => {
    metrics.track("authentication_start", { provider: "google" });
    const clientID =
      "398058461162-6hdd0ko3de1gmefnkpsgiidhkd364lnh.apps.googleusercontent.com";
    const { oauthGoogleRedirectUrl } = webConfig;
    openAuthWindow(
      `https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile&response_type=code&state=${searchParams.get("inviteCode") || ""}&prompt=select_account&redirect_uri=${encodeURIComponent(
        oauthGoogleRedirectUrl,
      )}&client_id=${clientID}`,
      "Google",
      previousUrl,
      "login",
    );
  };

  const handleApple = () => {
    metrics.track("authentication_start", { provider: "apple" });
    const clientID = "com.mindstone.web";
    const { oauthAppleRedirectUrl } = webConfig;
    //TODO: Safari state for redirect + code
    if (isSafari()) {
      let state = btoa(`${previousUrl}$`);
      if (searchParams.get("inviteCode")) {
        state = btoa(`${previousUrl}$${searchParams.get("inviteCode")}`);
      }
      window.location.href = `https://appleid.apple.com/auth/authorize?scope=email%20name&response_mode=form_post&response_type=code&redirect_uri=${encodeURIComponent(
        oauthAppleRedirectUrl,
      )}&state=${state}&client_id=${clientID}`;
      return;
    }
    let state = "";
    if (searchParams.get("inviteCode")) {
      state = btoa(`$${searchParams.get("inviteCode")}`);
    }
    openAuthWindow(
      `https://appleid.apple.com/auth/authorize?scope=email%20name&response_mode=form_post&state=${state}&response_type=code&redirect_uri=${encodeURIComponent(
        oauthAppleRedirectUrl,
      )}&client_id=${clientID}`,
      "Apple",
      previousUrl,
      "login",
    );
  };

  const handleLinkedin = () => {
    metrics.track("authentication_start", { provider: "linkedin" });
    const clientID = "78y1d813p23i03";

    const { oauthLinkedinRedirectUrl } = webConfig;
    openAuthWindow(
      `https://www.linkedin.com/oauth/v2/authorization?scope=openid%20profile%20email&response_type=code&state=${searchParams.get("inviteCode") || ""}&redirect_uri=${encodeURIComponent(
        oauthLinkedinRedirectUrl,
      )}&client_id=${clientID}`,
      "Linkedin",
      previousUrl,
      "login",
    );
  };

  return (
    <Login
      handleGoogle={handleGoogle}
      handleApple={handleApple}
      handleLinkedin={undefined}
      reset={reset}
      appRoutes={AppRoutes}
      routerUtils={{ useLocation, Link }}
    />
  );
}
